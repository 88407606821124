/* General styles */

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--blue);
}
