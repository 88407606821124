:root {
  --orange: #f7936f;
  --hover-color: #e1331f;
  --blue: #4c6fff;
  --bColor: #7e7e7e;
  --white: #ffffff;
  --black: #000000;
  --heading-dark: #27272e;
  --text-body-dark: #425466;
  --theme-primary-light: #7895ff;
  --dashboard-bg: #f7fafc;
  --success: #66cb9f;
  --body-muted: #718096;
  --error: #f16063;
  --soft-blue: #e1e8ff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

/* modal */
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background: rgba(0, 0, 0, 0.1) !important;
}

/* alerts */
.alert__cont {
  align-items: flex-start !important;
  max-width: 47.5rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  border-radius: 1.6rem !important;
}

.alert__cont div:nth-child(2) {
  justify-content: flex-start;
  display: flex;
  align-self: center;
  align-items: center;
}

.alert__icon-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  border-radius: 50%;
  padding: 0.6rem;
  /* color: #fff !important; */
}

.alert__icon-cont-success {
  background: #4aae8c;
}

.alert__icon-cont-error {
  background-color: rgb(192, 86, 86);
}

.alert__success {
  background: #66cb9f !important;
  color: #ffffff !important;
}

.alert__error {
  background: rgb(227, 107, 107) !important;
  color: #ffffff !important;
}

div.go2072408551 {
  padding: 16px;
}

div.go3958317564 {
  font-size: 14px;
  line-height: 200%;
  margin: 0 16px;
}

div[role="presentation"] {
  z-index: 30000 !important;
}
.css-8ndowl {
  z-index: 99999484 !important;
}
.overflow-y {
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a6b7d4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e4ecf7;
}

/* Table general styles */
/* remove "rows per page" */
.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input,
.kfUqml .css-pdct74-MuiTablePagination-selectLabel {
  display: none;
}

.exam-images__buttons {
  flex-wrap: wrap;
  margin-top: 1.2rem;
  display: flex;
}

.lefty {
  z-index: 22727 !important;
}

/* NONE CONTAINER */
.none-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.none-img {
  height: 200px;
  margin-top: 64px;
}

.none-container h3 {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 22px;
  margin-top: 24px;
  color: #425466;
}

.none-container p {
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 20px;
  max-width: 320px;
  text-align: center;
  color: #425466;
  margin-top: 16px;
}

.none-container .send-btn {
  margin-top: 30px;
  max-width: 273px;
  cursor: pointer;
  margin-bottom: 64px;
  align-items: center;
}

.none-container .send-btn svg {
  margin-right: 20px;
}

/* Google translate widget */
.skiptranslate,
.goog-te-gadget,
.goog-logo-link:link {
  /* color: #ffffff90 !important; */
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Text/Body/Muted */

  color: #718096 !important;
  /* font-family: "Inter" !important; */
}
.goog-te-combo {
  background: #1b2b42;
  outline: none;
  border: none;
  color: white;
  padding: 8px;
  font-family: "Inter";
  border-radius: 4px;
  cursor: pointer;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 10px;
}

#root > div:first-child {
  z-index: 9948484899 !important;
}

#google_translate_element {
  position: fixed;
  z-index: 838484848;
  bottom: 0px;
  left: 0;
}

.goog-te-combo {
  color: #718096 !important;
}

iframe[style="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
  display: none !important;
}

.transfer-steps li {
  font-weight: 400;
  font-size: 14px;
  line-height: 162%;
  text-align: center;
  font-feature-settings: "salt" on;
  color: #425466;
  margin-top: 10px;
  text-align: left;
  list-style: auto;
}

.transfer-steps {
  margin-top: 1em;
}

small.error {
  color: var(--error);
  display: inline-block;
  margin-top: 8px;
}
